import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import Layout from "../components/layout"

const News = ({ data }) => {
  const news = data.allContentfulNews.nodes
  const newsPage = data.allContentfulNewsPage.nodes[0]

  return (
    <Layout>
      <Seo title={"Our team"} description={"Team of Dryline Partners"} />
      <div className="container flex items-start mx-auto pb-32">
        <div className="bg-white p-5 lg:py-20">
          <div className="container p-5 relative">
            <h1 data-sal="slide-left" data-sal-duration="1000" className="text-purple pb-20">
              {newsPage.subtitle}
            </h1>
            <h2 data-sal="slide-left" data-sal-duration="1000" data-sal-delay="200" className="text-3xl lg:text-5xl font-semibold text-black pb-20 lg:pr-40">
              {newsPage.title}
            </h2>
            <div className="flex flex-col divide-y divide-peach border-t border-peach">
              {news?.map((article, index) => {
                return (
                  <div data-sal="slide-up" data-sal-duration="1000" data-sal-delay="200" className="flex divide-x divide-peach" index={index}>
                    <a className="flex flex-col lg:w-5/6 lg:px-20" href={article.link} rel="noreferrer" target="_blank">
                      <span className="text-purple pt-10">
                        {article.date}
                      </span>
                      <h3 className="text-2xl w-56 pr-5 lg:w-auto lg:text-4xl py-5">
                        {article.title}
                      </h3>
                      <p className="pb-10">{article.source}</p>
                    </a>
                    <a className="lg:w-1/6 p-5 py-20 lg:p-20" href={article.link} rel="noreferrer" target="_blank">
                      <StaticImage
                        src="../images/link-arrow.png"
                        width={100}
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="Arrow"
                      />
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default News

export const pageQuery = graphql`
  query NewsQuery {
    allContentfulNewsPage {
      nodes {
        title
        subtitle
      }
    }
    allContentfulNews(sort: { fields: date, order: DESC }) {
      nodes {
        title
        source
        link
        date(formatString: "MM/YYYY")
      }
    }
  }
`
